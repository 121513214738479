.skip-link {
  text-align: center;
  margin-right: 1rem;
  line-height: 24px;
  position: absolute;
  height: 24px;
  max-height: 0;
  overflow: hidden;
  background-color: theme('colors.neutral.800');
  color: theme('colors.white');
}

.skip-link:focus-within {
  max-height: 24px;
  position: static;
}
