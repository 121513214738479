.mobileNav {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  height: 100vh;
  width: min(90vw, 400px);
  background-color: theme('colors.white');
  transition: max-width 200ms ease-in-out;
  max-width: 0px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.mobileNav.open {
  max-width: 400px;
}
